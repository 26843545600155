import { createApp } from '../app'

import * as Sentry from '@sentry/vue'
import { Confetti } from 'vue-confetti'
import VueSilentbox from 'vue-silentbox'
import 'vue-silentbox/dist/style.css'

export { render as onRenderClient }

let app
let aboutToUnload = false

async function render(pageContext) {
  // Hydration after SSR
  if (pageContext.isHydration) {
    const instance = createApp(pageContext)
    app = instance.app
    instance.store.state.value = pageContext.initialStoreState

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data,
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.thirdPartyErrorFilterIntegration({
          filterKeys: ['W0NLQRbOfxItOYBF'],
          behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
        }),
      ],
      environment: import.meta.env.VITE_SENTRY_ENV,
      release: import.meta.env.VITE_SENTRY_RELEASE,
      logErrors: true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: import.meta.env.PROD ? 0.1 : 1.0,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: import.meta.env.PROD ? 0.25 : 1.0,

      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],

      // Called for message and error events
      // beforeSend(event, hint) {
      //   const error = hint.originalException

      //   if (aboutToUnload && error) {
      //     // Don't send errors to Sentry produced when the user is leaving the page. They're most likely related to interrupted
      //     // dynamic modules hydration
      //     console.log(
      //       `The following error was not sent to Sentry, because it was raised after the user is leaving the page.`,
      //     )
      //     console.log(error.toString())

      //     return null
      //   }

      //   if (error) {
      //     console.log(`Error being sent to Sentry`)
      //     console.log(error.toString())
      //   }
      //   return event
      // },
    })

    // Only works on client-side, so we need to add it here
    app.provide('confetti', new Confetti())
    app.use(VueSilentbox)

    app.mount('#jrCB')

    // window.addEventListener('visibilitychange', () => {
    //   if (document.hidden) {
    //     aboutToUnload = true
    //     console.log(`User is leaving page: ${window.location}`)
    //   }
    // })
  } else {
    app.changePage(pageContext)
  }
}
